<template>
<div>
  <div id="navbar">
    <router-link to="/"><h1 id="logo-text"> M </h1></router-link>
  </div>
  <div id="initial-viewport-container">
      <video loop muted playsinline src="../assets/starry-4k.mp4" id="parallax-video" poster></video>
      <div id="parallax-content-left" v-if="!formWasSubmitted">
        <p id="description-heading"> Hi, my name is Manuja, and I enjoy building innovative and useful software. <br> Got a <router-link to="/#projects" style="color: #3f3"> cool project </router-link> in mind ? Let's talk. </p>
        <p id="description-text"> Special consideration given to novel database architecture implementation ideas including, but not limited to, proving new concepts in the database architecture arena, and adapting existing architectures to process streaming datasets requiring thoughtful and complex analysis.  </p>
      </div>
      <div id="parallax-content" v-if="!formWasSubmitted">
        <div id="parallax-form">
        <h1> Build The Future</h1>
        <v-form 
        ref="form"
        autocomplete="off">
          <v-text-field
          label="First Name"
          v-model="firstName"
          dark
          required
          ></v-text-field>
          <v-text-field
          label="Last Name"
          v-model="lastName"
          required
          dark
          />
          <v-text-field
          label="Email"
          v-model="email"
          :rules="emailRules"
          required
          dark
          />
          <v-textarea
          label="Your vision"
          v-model="vision"
          placeholder="Tell me about your idea"
          dark
          outlined=""
          />
          <v-btn block @click="submit"> Submit </v-btn>
        </v-form>
        </div>
      </div>
      <div id="parallax-content-center" v-else>
          <h1 style="font-size: 3rem;"> The Future Awaits </h1>
          <p id="formSubmittedText"> Thanks {{ firstName }}, your response was recorded.  <br> Expect to hear back soon. </p>
          <router-link to="/"><v-btn style="margin-top:1rem;" block> Home </v-btn></router-link>
      </div>
    </div>
</div>
</template>


<script>
export default {
  name: "Apply",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      vision:"",
      formWasSubmitted: false,
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+\..+/.test(v) || "Must use valid email"
      ]
    }
  },
  methods: {
    submit(){
      if (!this.firstName || !this.lastName || !this.email || !this.vision) {
        alert("You must fill out all fields before submitting.");
        return;
      }
      if (!this.$refs.form.validate()) {
        alert("Invalid email detected. Please use a valid email address.");
        return;
      }
      const body = `firstName=${this.firstName}&lastName=${this.lastName}&email=${this.email}&vision=${this.vision}`;
      fetch("https://submissions.desilva.codes/apply", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: body
      })
      .catch((error) => {
        console.log(error);
      })
      this.formWasSubmitted = true;
    },
  },
  mounted() {
    document.getElementById("parallax-video").play();
    document.getElementById("parallax-video").addEventListener("playing", () => {
      if (document.getElementById("initial-viewport-container").style.backgroundColor != "transparent") {
        document.getElementById("initial-viewport-container").style.backgroundColor = "transparent";
      }
    });

  }
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Katibeh&display=swap');

#initial-viewport-container {
  height: 100vh;
  max-width: 100%;
  min-height: 500px;
  background-attachment: fixed;
  background-size:cover;
  background-position: top center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  background-color: black;
}

#parallax-content {
  color:white;
  text-align: center;
  margin-right: 2rem;

}

#parallax-content-left {
    color: white;
    text-align: left;
    margin-bottom: 12rem;
}

#parallax-content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 1rem;
}
#parallax-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  top: 0;
  z-index: -1;
}

#parallax-content h1 {
  font-size: 3rem;
}

#navbar {
  position: absolute;
  top: 3.33%;
  left: 6.66%;
  right: 6.66%;
  color: white;
}

#formSubmittedText {
  padding-top: 1rem;
  font-weight: bold;
}

#logo-text {
  font-family: 'Katibeh', sans-serif;
  font-size: 3rem;
  color: white;
}

#description-heading {
  font-weight: bold;
}

#description-text {
  padding-top: 1rem;
  font-family: 'Mukta', sans-serif;
  width: 600px;
}

video::-webkit-media-controls-start-playback-button{
  opacity: 0;
  display: none;
}

@media only screen and (max-width: 700px)  {

  #parallax-content-left {
    width: fit-content;
    text-align: center;
    margin-left: 0rem;
    margin-bottom: 0rem;
    margin-right: 0rem;
  }

  #initial-viewport-container {
    flex-direction: column;
    justify-content: center;
  }

  #parallax-content {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 2rem;
  }
  #description-text {
    display: none;
  }
  
}
</style>